.toast {
  z-index: var(--zIndexToast);
  border-radius: var(--br3);
  font-size: var(--fs3);
  padding: var(--sp4) var(--sp5);
  box-shadow: var(--boxShadow0);
  font: inherit;
  line-height: 1.5;
}

.toastSuccess {
  background-color: var(--pltSuccess000);
  /* border: 1px solid var(--pltSuccess100); */
  color: var(--pltSuccess500);
}

.toastSuccess svg {
  color: var(--pltSuccess500);
}

.toastError {
  background-color: var(--pltDanger000);
  /* border: 1px solid var(--pltDanger100); */
  color: var(--pltDanger500);
}

.toastError svg {
  color: var(--pltDanger500);
}
