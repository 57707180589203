/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 24px;
  height: 12px;
  left: var(--sp4);
  top: 15px;
  z-index: 1 !important;
}

/* TODO - remove when we remove banner */
body[data-is-showing-banner="yes"] .bm-burger-button {
  top: calc(130px + 15px);
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  /* background: #a90000; */
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  display: none;
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: var(--pltNeutral300);
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  transition: all 0.2s ease 0s !important;
}

/* General sidebar styles */
.bm-menu {
  background: white;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  padding: var(--sp2) 0;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.6) !important;
}
