.button {
  display: inline-flex;
  flex: none;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: var(--fs2);
  font-weight: 500;
  box-sizing: border-box;
  margin: 0;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  text-decoration: none;
  padding: var(--buttonPadding);
  height: var(--buttonHeight);
  outline: none;
  user-select: none;
  transition: background-color 0.2s;
  border: none;
  border-radius: var(--buttonBorderRadius);
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
