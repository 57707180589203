.buttonSecondary {
  background: white;
  color: var(--pltPrimary);
  border: 2px solid var(--pltPrimary);
}

.buttonSecondary:focus {
  box-shadow: var(--inputFocusedBoxShadow);
}

.buttonSecondary:not(:disabled):active {
  opacity: 0.8;
}

@media (hover: hover) {
  .buttonSecondary:not(:disabled):hover {
    background-color: var(--pltPrimary);
    color: white;
  }

  .buttonSecondary:not(:disabled):active {
    opacity: 0.8;
  }
}
