.root {
  display: grid;
  gap: var(--sp3) var(--sp3);
}

.xxs {
  margin-block-start: var(--sp2);
}

.xs {
  margin-block-start: var(--sp3);
}

.sm {
  margin-block-start: var(--sp4);
}

.md {
  margin-block-start: var(--sp5);
}

.lg {
  margin-block-start: var(--sp6);
}

@media (min-width: 768px) {
  .root {
    grid-auto-flow: column;
    justify-content: start;
    align-items: center;
  }
}
