:root {
  --pltPrimaryLll: hsl(250.2, 41.4%, 96%);
  --pltPrimaryLl: hsl(250.2, 41.4%, 91%);
  --pltPrimaryL: hsl(250.2, 41.4%, 60%);
  --pltPrimary: hsl(250.2, 41.4%, 38.8%);

  --pltSecondary: rgb(220, 0, 234);

  --pltNeutral000: hsl(216, 23%, 97%);
  --pltNeutral100: hsl(214, 5%, 91%);
  --pltNeutral200: hsl(210, 6%, 82%);
  --pltNeutral300: hsl(211, 3%, 65%);
  --pltNeutral400: hsl(211, 1%, 53%);
  --pltNeutral500: hsl(211, 2%, 43%);
  --pltNeutral600: hsl(209, 4%, 37%);
  --pltNeutral700: hsl(209, 8%, 30%);
  --pltNeutral800: hsl(209, 10%, 25%);
  --pltNeutral900: hsl(210, 14%, 16%);

  --pltSuccess000: hsl(125, 65%, 93%);
  --pltSuccess100: hsl(127, 65%, 85%);
  --pltSuccess200: hsl(124, 63%, 74%);
  --pltSuccess300: hsl(123, 53%, 55%);
  --pltSuccess400: hsl(123, 57%, 45%);
  --pltSuccess500: hsl(122, 73%, 35%);
  --pltSuccess600: hsl(122, 80%, 29%);
  --pltSuccess700: hsl(125, 79%, 26%);
  --pltSuccess800: hsl(125, 86%, 20%);
  --pltSuccess900: hsl(125, 97%, 14%);

  --pltDanger000: hsl(0, 100%, 95%);
  --pltDanger100: hsl(0, 100%, 87%);
  --pltDanger200: hsl(0, 100%, 80%);
  --pltDanger300: hsl(0, 91%, 69%);
  --pltDanger400: hsl(0, 83%, 62%);
  --pltDanger500: hsl(356, 75%, 53%);
  --pltDanger600: hsl(354, 85%, 44%);
  --pltDanger700: hsl(352, 90%, 35%);
  --pltDanger800: hsl(350, 94%, 28%);
  --pltDanger900: hsl(348, 94%, 20%);

  --pltInfo000: hsl(45, 100%, 96%);
  --pltInfo100: hsl(45, 90%, 88%);
  --pltInfo200: hsl(45, 86%, 81%);
  --pltInfo300: hsl(43, 90%, 76%);
  --pltInfo400: hsl(43, 89%, 70%);
  --pltInfo500: hsl(42, 78%, 60%);
  --pltInfo600: hsl(42, 63%, 48%);
  --pltInfo700: hsl(43, 72%, 37%);
  --pltInfo800: hsl(43, 77%, 27%);
  --pltInfo900: hsl(43, 86%, 17%);

  --ff0: "Roboto", sans-serif;
  --ff1: "Baloo Tamma 2", sans-serif;

  /* type scale */
  /* p91 */
  --fs0: 13px;
  --fs1: 14px;
  --fs2: 15px;
  --fs3: 16px;
  --fs4: 17px;
  --fs5: 18px;
  --fs6: 22px;
  --fs7: 28px;
  --fs8: 34px;
  --fs9: 48px;
  --fsA: 60px;
  --fsB: 72px;

  /* spacing */
  --sp0: 2px;
  --sp1: 4px;
  --sp2: 8px;
  --sp3: 12px;
  --sp4: 16px;
  --sp5: 24px;
  --sp6: 32px;
  --sp7: 48px;
  --sp8: 64px;
  --sp9: 96px;
  --spA: 128px;
  --spB: 256px;
  --spC: 384px;
  --spD: 512px;
  --spE: 640px;
  --spF: 768px;

  /* border-radius */
  --br0: 1px;
  --br1: 2px;
  --br2: 4px;
  --br3: 8px;
  --br4: 12px;
  --br5: 16px;
  --br6: 20px;

  /* box-shadow */
  /* p166 */
  --boxShadow0: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  --boxShadow1: 0 3px 6px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.12);
  --boxShadow2: 0 10px 20px rgba(0, 0, 0, 0.15), 0 3px 6px rgba(0, 0, 0, 0.1);
  --boxShadow3: 0 15px 25px rgba(0, 0, 0, 0.15), 0 5px 10px rgba(0, 0, 0, 0.05);
  --boxShadow4: 0 20px 40px rgba(0, 0, 0, 0.2);

  --zIndexSidebar: 2;
  --zIndexHeaderMenu: 3;
  --zIndexHeader: 4;
  --zIndexTooltip: 5;
  --zIndexFloatingActionButton: 6;
  --zIndexDialogOverlay: 7;
  --zIndexDialogContent: 8;
  --zIndexToast: 9;

  --inputHeight: 42px;
  --inputSmHeight: 36px;
  --inputBorderRadius: var(--br3);
  --inputBorderColor: var(--pltNeutral200);
  --inputPaddingHorizontal: var(--sp4);
  --inputFocusedBorderColor: var(--pltPrimaryL);
  --inputFocusedBoxShadow: var(--pltPrimaryLl) 0 0 0 4px;
  --inputErroredBorderColor: var(--pltDanger500);
  --inputErroredBoxShadow: var(--pltDanger100) 0 0 0 4px;

  --buttonHeight: 42px;
  --buttonPadding: 0 20px;
  --buttonBorderRadius: var(--br3);

  --buttonSmHeight: 36px;
  --buttonSmPadding: 0 16px;
  --buttonSmBorderRadius: var(--br3);

  --pgContentLimitWidth: 1060px;
  --pgMarginHorizontal: var(--sp4);
  --pgMarginBlockStart: var(--sp4);
  --pgMarginBlockEnd: var(--sp5);
  --pgMargin: var(--pgMarginBlockStart) var(--pgMarginHorizontal)
    var(--pgMarginBlockEnd) var(--pgMarginHorizontal);

  /* If we want to support dark mode in the future,
  set differently based on prefers-color-scheme */
  --foregroundColor: white;
  --backgroundColor: var(--pltNeutral000);
  --backgroundTextColor: var(--pltNeutral700);
  --backgroundTextColorSecondary: var(--pltNeutral400);
}

@media screen and (hover: hover) and (min-width: 768px) {
  :root {
    --pgMarginBlockEnd: var(--sp8);
  }
}

body {
  font-family: var(--ff0);
  font-size: var(--fs2);
  font-display: optional;
  line-height: 1.4;
  background-color: var(--backgroundColor);
  color: var(--pltNeutral800);
  /* https://stackoverflow.com/a/11885330 */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

p {
  line-height: 1.5;
}

/* ::selection {
  background-color: var(--plt-supporting-2-000);
} */

::placeholder {
  color: var(--pltNeutral500);
}

@media print {
  header {
    display: none;
  }
}
