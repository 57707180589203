.menuButton {
  background-color: transparent;
  border: none;
  color: white;
  font: inherit;
  font-size: var(--fs1);
  line-height: 1;
  cursor: pointer;
  margin: 0;
  padding: var(--sp1) 0;
}

.menuButton svg {
  font-size: var(--fs5);
}

.menuButton:hover {
  opacity: 0.8;
}

.menuButtonArrow {
  margin-inline-start: 5px;
}

.menuButtonLayout {
  display: inline-flex;
  flex: none;
  justify-content: center;
  align-items: center;
  position: relative;
}
