.buttonXsNeutral {
  background-color: var(--pltNeutral000);
  border: 1px solid var(--pltNeutral200);
  color: var(--pltNeutral700);
  height: 30px;
  padding: var(--buttonSmPadding);
  padding-inline-start: var(--sp2);
  padding-inline-end: var(--sp2);
  border-radius: var(--br3);
  font-size: var(--fs1);
}

.buttonXsNeutral:focus {
  box-shadow: var(--pltNeutral100) 0 0 0 4px;
}

.buttonXsNeutral:not(:disabled):active {
  opacity: 0.7;
}

@media (hover: hover) {
  .buttonXsNeutral:not(:disabled):hover {
    opacity: 0.8;
  }

  .buttonXsNeutral:not(:disabled):active {
    opacity: 0.7;
  }
}
