.buttonSmNeutral {
  background-color: var(--pltNeutral000);
  border: 1px solid var(--pltNeutral200);
  color: var(--pltNeutral700);
  height: var(--buttonSmHeight);
  padding: var(--buttonSmPadding);
  border-radius: var(--buttonSmBorderRadius);
}

.buttonSmNeutral:focus {
  box-shadow: var(--pltNeutral100) 0 0 0 4px;
}

.buttonSmNeutral:not(:disabled):active {
  opacity: 0.7;
}

@media (hover: hover) {
  .buttonSmNeutral:not(:disabled):hover {
    opacity: 0.8;
  }

  .buttonSmNeutral:not(:disabled):active {
    opacity: 0.7;
  }
}
