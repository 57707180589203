.buttonTransparentPrimary {
  background: transparent;
  color: var(--pltPrimary);
  border-radius: var(--buttonBorderRadius);
}

.buttonTransparentPrimary:focus {
  box-shadow: var(--inputFocusedBoxShadow);
}

.buttonTransparentPrimary:not(:disabled):active {
  opacity: 0.8;
}

@media (hover: hover) {
  .buttonTransparentPrimary:not(:disabled):hover {
    background-color: var(--pltPrimaryLll);
  }

  .buttonTransparentPrimary:not(:disabled):active {
    opacity: 0.8;
  }
}
