.pressable {
  display: block;
  color: inherit;
  font: inherit;
  text-decoration: none;
  border: none;
  background: transparent;
  padding: 0;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}

.pressable:active {
  opacity: 0.7;
}

@media (hover: hover) {
  .pressable:hover {
    opacity: 0.8;
  }

  .pressable:active {
    opacity: 0.7;
  }
}
