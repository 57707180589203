.aligner {
  /* Needs to match breadrumb_link inline padding */
  margin-inline-start: calc(-1 * var(--sp3));
}

.breadcrumb {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
}

.breadcrumbSeparator {
  padding: 0 var(--sp1);
  color: var(--pltNeutral400);
  font-size: var(--fs5);
  font-weight: 400;
}

.breadcrumbLink {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  gap: 0 var(--sp2);
  color: var(--pltPrimary);
  fill: currentColor;
  font-size: var(--fs5);
  font-weight: 500;
  text-decoration: none;
  padding: var(--sp0) var(--sp3);
}

.breadcrumbLink > svg {
  width: 18px;
  height: 18px;
}

.breadcrumbLink:hover,
.breadcrumbLink:active {
  text-decoration: underline;
}
