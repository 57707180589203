.header {
  background-color: var(--pltPrimary);
  border-bottom: 1px solid var(--pltNeutral300);
  padding: var(--sp1) var(--sp4);
}

.header a {
  line-height: 1;
}

.header a:hover,
.header button:hover {
  opacity: 0.9;
}

.logo {
  height: 30px;
  width: 83px;
  /* Align manually, since the image has some space at the bottom */
  margin-top: 3px;
}

.headerLayout {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-content: space-between;
  align-items: center;
  gap: var(--sp5);
}

.headerLayout > div:first-child {
  /* end actions width */
  width: 96px;
}

.headerLinks {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  gap: var(--sp5);
}

.headerLink {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  gap: var(--sp1);
  color: white;
  font-size: var(--fs1);
}

.headerEndLayout {
  display: grid;
  grid-template-columns: auto auto;
  justify-self: end;
  align-items: center;
  gap: var(--sp5);
}

@media (min-width: 768px) {
  .headerLayout {
    display: grid;
    grid-template-columns: auto 1fr auto;
    justify-content: space-between;
    align-items: center;
    gap: var(--sp5);
  }
}
