.overlay {
  background: hsla(232, 43%, 13%, 0.7);
  backdrop-filter: blur(12px);
  z-index: var(--zIndexDialogOverlay);
}

.content {
  background: white;
  width: 90vw;
  overflow-y: scroll;
  padding: 0;
  margin: var(--sp8) auto;
  border-radius: var(--br3);
  z-index: var(--zIndexDialogContent);
  box-shadow: var(--boxShadow1);
}

.alertDialogLabel {
  font-size: var(--fs5);
  font-weight: 500;
  line-height: 1.3;
  color: var(--pltNeutral900);
}

.alertDialogHeader {
  display: grid;
  justify-content: space-between;
  align-items: center;
  gap: var(--sp4);
  padding: var(--sp4) var(--sp5);
  border-block-end: 1px solid var(--pltNeutral100);
}

.alertDialogBody {
  padding: var(--sp4) var(--sp5);
}

.alertDialogActions {
  display: grid;
  grid-auto-flow: column;
  justify-content: end;
  align-items: center;
  gap: var(--sp4);
  padding: var(--sp3) var(--sp5);
  border-block-start: 1px solid var(--pltNeutral100);
  background-color: var(--pltNeutral000);
}

.closeButtonContainer {
  display: block;
  position: absolute;
  top: var(--sp2);
  left: calc(-1 * var(--sp2));
}

.closeButton {
  display: inline-flex;
  flex: none;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: var(--fs5);
  box-sizing: border-box;
  margin: 0;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  text-decoration: none;
  padding: 0 var(--sp4);
  height: var(--buttonHeight);
  outline: none;
  user-select: none;
  /* transition: background-color 0.2s; */
  border: none;
  background-color: transparent;
  color: var(--pltNeutral500);
  border-radius: var(--br4);
}

.closeButton:focus {
  box-shadow: var(--inputFocusedBoxShadow);
}

.closeButton:active {
  background: hsla(0, 100%, 100%, 0.2);
}

.alertDialogLoadingContainer {
  width: 150px;
  padding: 0;
  border-radius: var(--br3);
  z-index: var(--zIndexDialogContent);
}

.alertDialogLoading {
  display: grid;
  justify-content: center;
  align-items: center;
  padding: var(--sp4);
}

@media (min-width: 768px) {
  .content {
    width: 80vw;
    max-width: 560px;
    margin: var(--sp8) auto;
  }

  .closeButtonContainer {
    top: var(--sp4);
    left: var(--sp4);
  }
}

@media (hover: hover) {
  .closeButton:hover {
    background: hsla(0, 100%, 100%, 0.1);
  }

  .closeButton:active {
    background: hsla(0, 100%, 100%, 0.2);
  }
}
