.fields {
  display: grid;
  grid-auto-flow: row;
  gap: var(--sp5) 0;
}

.fieldsSm {
  gap: var(--sp4) 0;
}

.fieldsColumns {
  display: grid;
  grid-auto-flow: row;
  gap: var(--sp5) var(--sp3);
}

.fieldsColumnsSm {
  gap: var(--sp4) var(--sp3);
}

.fieldsColumns input,
.fieldsColumns select {
  /* Prevent overhang */
  width: 100%;
}

@media (min-width: 768px) {
  .fieldsColumns {
    grid-auto-flow: column;
  }
}
