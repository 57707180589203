.sideMenuLink {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  gap: var(--sp4);
  padding: var(--sp3) var(--sp4);
  font-size: var(--fs3);
}

.sideMenuLink svg {
  color: var(--pltNeutral500);
}

.separator {
  border-block-end: 1px solid var(--pltNeutral100);
  padding-block-start: var(--sp2);
  margin-block-end: var(--sp2);
}
