.root {
  display: grid;
  justify-content: center;
  align-items: center;
  margin: var(--sp6) var(--pgMarginHorizontal);
}

.inner {
  margin: 0 auto;
  max-width: 500px;
}
