.buttonDanger {
  background: var(--pltDanger600);
  color: white;
  font-weight: 500;
  border: 1px solid var(--pltDanger600);
}

.buttonDanger:focus {
  box-shadow: var(--pltDanger100) 0 0 0 4px;
}

.buttonDanger:not(:disabled):active {
  opacity: 0.8;
}

@media (hover: hover) {
  .buttonDanger:not(:disabled):hover {
    opacity: 0.9;
  }

  .buttonDanger:not(:disabled):active {
    opacity: 0.8;
  }
}
