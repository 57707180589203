.top {
  /* background-color: var(--backgroundColor); */
  border-block-end: 1px solid var(--pltNeutral100);
  padding: var(--sp4) 0 0 0;
}

.main {
}

.limit {
  margin: 0 auto;
  max-width: var(--pgContentLimitWidth);
  padding-inline-start: var(--sp4);
  padding-inline-end: var(--sp4);
}
