.buttonPrimary {
  background: var(--pltPrimary);
  color: white;
  font-weight: 500;
  border: 1px solid var(--pltPrimary);
}

.buttonPrimary:focus {
  box-shadow: var(--inputFocusedBoxShadow);
}

.buttonPrimary:not(:disabled):active {
  opacity: 0.8;
}

@media (hover: hover) {
  .buttonPrimary:not(:disabled):hover {
    opacity: 0.9;
  }

  .buttonPrimary:not(:disabled):active {
    opacity: 0.8;
  }
}
