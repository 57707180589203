[data-reach-menu-list] {
  font: inherit;
  border: none;
  border-radius: var(--br3);
  box-shadow: var(--boxShadow1);
  padding: var(--sp2) 0;
  background: white;
}

[data-reach-menu-item] {
  padding: var(--sp2) var(--sp4);
}

[data-reach-menu-item][data-selected] {
  background-color: var(--pltPrimaryLll);
  color: inherit;
}

.menuButtonArrow {
  margin-inline-start: var(--sp1);
}

.menuItemTextDanger {
  color: var(--pltDanger600);
}
