.input {
  font-family: inherit;
  font-size: var(--fs3);
  vertical-align: middle;
  color: var(--pltNeutral900);
  background-color: hsl(0, 0%, 100%);
  border-radius: var(--inputBorderRadius);
  padding: 0 var(--sp3);
  border: none;
  -webkit-appearance: none;
  /* box-shadow: inset 0 1px 2px 0 var(--pltNeutral100); */
  outline: 0;
  height: var(--inputHeight);
}

.inputSm {
  height: var(--inputSmHeight);
}

.input:focus {
  box-shadow: var(--inputFocusedBoxShadow);
  border-color: var(--inputFocusedBorderColor);
}

.input:disabled,
.input[readonly] {
  opacity: 0.6;
  cursor: not-allowed;
}

.container {
  display: grid;
}

@media (min-width: 768px) {
  .input {
    font-size: var(--fs2);
  }
}
