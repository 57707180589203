.buttonSmPrimary {
  background: var(--pltPrimary);
  color: white;
  font-weight: 500;
  border: 1px solid var(--pltPrimary);
  height: var(--buttonSmHeight);
  padding: var(--buttonSmPadding);
  border-radius: var(--buttonSmBorderRadius);
}

.buttonSmPrimary:focus {
  box-shadow: var(--inputFocusedBoxShadow);
}

.buttonSmPrimary:not(:disabled):active {
  opacity: 0.8;
}

@media (hover: hover) {
  .buttonSmPrimary:not(:disabled):hover {
    opacity: 0.9;
  }

  .buttonSmPrimary:not(:disabled):active {
    opacity: 0.8;
  }
}
