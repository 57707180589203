.root {
  margin: 20vh var(--sp4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading {
  font-size: var(--fs6);
  color: var(--pltNeutral900);
  font-weight: 700;
  margin-block-end: var(--sp3);
}

.subHeading {
  font-size: var(--fs4);
  color: var(--pltNeutral600);
  margin-block-end: var(--sp5);
}

@media (min-width: 768px) {
  .root {
    margin: 20vh auto;
  }
}
