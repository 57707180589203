.badge {
  display: flex;
  flex: none;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: var(--pltDanger600);
  font-size: var(--fs0);
  line-height: 1;
  height: 16px;
  border-radius: 6px;
  padding: 0 6px;
}
