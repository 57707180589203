.menuItemLayout {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  gap: var(--sp2);
}

.menuItemLayout svg {
  color: var(--pltNeutral400);
}

.section {
  padding: var(--sp2) 0;
}

.userSection {
  padding: var(--sp2) var(--sp4) var(--sp3);
  border-block-end: 1px solid var(--pltNeutral100);
}

.userName {
  color: var(--pltNeutral900);
  font-size: var(--fs3);
  line-height: 1.2;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.signOutSection {
  padding-block-start: var(--sp2);
  border-block-start: 1px solid var(--pltNeutral100);
}

.businesses {
  border-block-end: 1px solid var(--pltNeutral100);
}

.businessesHeader {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  align-items: baseline;
  gap: var(--sp8);
  padding: var(--sp3) var(--sp4) var(--sp2);
  border-block-end: 1px solid var(--pltNeutral100);
}

.businessHeading {
  font-weight: 500;
  font-size: var(--fs1);
  color: var(--pltNeutral400);
  text-transform: uppercase;
}

.businessListContainer {
  padding: var(--sp2) 0;
  max-height: 200px;
  overflow-y: scroll;
}

.businessListEmpty,
.businessListLoading {
  background-color: var(--pltNeutral000);
  border: 1px solid var(--pltNeutral100);
  border-radius: var(--br4);
  padding: var(--sp3) var(--sp4);
  margin: 0 var(--sp4);
  color: var(--pltNeutral600);
}

.businessListLoading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.business {
  display: grid;
  grid-template-columns: 1fr auto;
  justify-content: space-between;
  align-items: center;
}

.settingIcon {
  font: inherit;
  color: var(--pltNeutral400);
  /* Align manually */
  margin-block-start: 3px;
}

.selectedIcon {
  font: inherit;
  font-size: var(--fs5);
  color: var(--pltNeutral800);
  margin-inline-end: var(--sp1);
}

.menuItemBusiness {
  display: grid;
  grid-template-columns: auto auto 1fr;
  justify-content: start;
  align-items: center;
  gap: var(--sp3);
}

.menuItemBusinessName {
  color: var(--pltNeutral600);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.settingButton {
  padding: var(--sp0) var(--sp4);
}
