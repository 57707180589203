.buttonSecondaryDanger {
  background-color: var(--pltNeutral000);
  border: 1px solid var(--pltNeutral200);
  color: var(--pltDanger600);
}

.buttonSecondaryDanger:focus {
  box-shadow: var(--pltNeutral100) 0 0 0 4px;
}

.buttonSecondaryDanger:not(:disabled):active {
  opacity: 0.7;
}

@media (hover: hover) {
  .buttonSecondaryDanger:not(:disabled):hover {
    opacity: 0.8;
  }

  .buttonSecondaryDanger:not(:disabled):active {
    opacity: 0.7;
  }
}
