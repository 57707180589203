.header {
  padding: var(--sp1) var(--sp4) var(--sp2);
  border-block-end: 1px solid var(--pltNeutral100);
}

.heading {
  font-weight: 500;
  font-size: var(--fs1);
  color: var(--pltNeutral400);
  text-transform: uppercase;
}

.listContainer {
  max-height: 300px;
  overflow-y: scroll;
}

.listEmpty,
.listLoading {
  background-color: var(--pltNeutral000);
  border: 1px solid var(--pltNeutral100);
  border-radius: var(--br4);
  padding: var(--sp3) var(--sp4);
  margin: var(--sp3) var(--sp4);
  color: var(--pltNeutral600);
}

.listLoading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.listItemSeparator {
  border-block-end: 1px solid var(--pltNeutral100);
}

.listItem {
  padding: var(--sp3) var(--sp4);
}

.listItemTextEmphasis {
  font-weight: 500;
  color: var(--pltNeutral900);
}

.listItemTextSubEmphasis {
  font-weight: 500;
  color: var(--pltNeutral600);
}

.notificationCountBadgeContainer {
  position: absolute;
  left: -11px;
  top: -5px;
}
