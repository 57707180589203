.popoverInner {
  font: inherit;
  border: none;
  border-radius: var(--br3);
  box-shadow: var(--boxShadow1);
  padding: var(--sp2) 0;
  background: white;
  width: 300px;
}

.popoverInner [data-reach-menu-items] {
  font: inherit;
  border: none;
  background: inherit;
  outline: none;
  padding: 0;
}
