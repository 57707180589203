.label {
  display: grid;
}

.labelInner {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: baseline;
}

.labelInnerWithEnd {
  justify-content: space-between;
}

.labelTextContainer {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: start;
  justify-self: start;
  gap: var(--sp1);
  margin-block-end: var(--sp1);
  font-weight: 500;
}

.labelText,
.labelTextRequiredIndicator {
  color: var(--pltNeutral700);
}

.labelTextRequiredIndicator {
  font-size: var(--fs0);
}

.labelEndContainer {
  justify-self: end;
}
