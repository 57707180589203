.buttonTransparentNeutral {
  background: transparent;
  color: var(--pltNeutral700);
  border-radius: var(--buttonBorderRadius);
}

.buttonTransparentNeutral:focus {
  box-shadow: var(--pltNeutral100) 0 0 0 4px;
}

.buttonTransparentNeutral:not(:disabled):active {
  opacity: 0.8;
}

@media (hover: hover) {
  .buttonTransparentNeutral:not(:disabled):hover {
    background-color: var(--pltNeutral100);
  }

  .buttonTransparentNeutral:not(:disabled):active {
    opacity: 0.8;
  }
}
