.navList {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  overflow-x: auto;
  position: relative;
  top: 1px;
  /* So scrollbar doesn't cover up the list */
  padding-block-end: var(--sp3);
  margin-block-end: calc(-1 * var(--sp3));
}

.link {
  display: block;
  line-height: 1;
  color: var(--pltNeutral600);
  background: none;
  border: none;
  border-bottom: 2px solid transparent;
  font-size: var(--fs1);
  padding: var(--sp4) var(--sp4);
  text-decoration: none;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
}

.link:hover,
.link:active {
  color: var(--pltNeutral700);
  border-color: var(--pltNeutral200);
}

.linkActive,
.linkActive:hover,
.linkActive:active {
  color: var(--pltPrimary);
  font-weight: 500;
  border-color: var(--pltPrimary);
}

.linkDisabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.navTabLayout {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  align-items: center;
  gap: var(--sp2);
}

.link svg {
  color: var(--pltNeutral300);
}

.linkActive svg,
.linkActive:hover svg,
.linkActive:active svg {
  color: var(--pltPrimaryL);
}
