.buttonSmTransparentPrimary {
  background: transparent;
  color: var(--pltPrimary);
  border-radius: var(--buttonBorderRadius);
  height: var(--buttonSmHeight);
  padding: var(--buttonSmPadding);
  border-radius: var(--buttonSmBorderRadius);
}

.buttonSmTransparentPrimary:focus {
  box-shadow: var(--inputFocusedBoxShadow);
}

.buttonSmTransparentPrimary:not(:disabled):active {
  opacity: 0.8;
}

@media (hover: hover) {
  .buttonSmTransparentPrimary:not(:disabled):hover {
    background-color: var(--pltPrimaryLll);
  }

  .buttonSmTransparentPrimary:not(:disabled):active {
    opacity: 0.8;
  }
}
