.link {
  cursor: pointer;
  background: none;
  color: var(--pltPrimary);
  font-weight: 400;
  font-size: var(--fs2);
  font-family: var(--ff0);
  text-decoration: underline;
  border: none;
  margin: 0;
  padding: 0;
  text-align: start;
  outline: none;
  text-decoration: underline;
}

.linkSm {
  font-size: var(--fs1);
}

.link:hover,
.link:active {
  text-decoration: underline;
}
